<template>
	<b-modal
		v-model="openDialogUpload"
		:width="modalSize"
		scroll="keep"
		:can-cancel="false"
	>
		<div class="card">
			<b-loading
				v-model="isLoading"
				:is-full-page="false"
				:can-cancel="false"
			/>

			<div class="card-head">
				<div
					class="is-flex pt-3"
					:class="{
						'is-justify-content-space-between': !isMobile,
						'is-flex-direction-column-reverse': isMobile
					}"
				>
					<div
						class="column is-6-tablet is-4-desktop is-3-widescreen is-10-mobile is-offset-1-mobile"
						:class="{
							'mt-5 ml-6': !isMobile,
						}"
					>
						<div class="is-flex is-justify-content-space-around pb-2">
							<span :class="[{ 'progress-label-active': getActiveStep == 1 }, 'is-size-7 disable-text']">Etapa 1</span>
							<span :class="[{ 'progress-label-active': getActiveStep == 2 }, 'is-size-7 disable-text']">Etapa 2</span>
							<span :class="[{ 'progress-label-active': getActiveStep == 3 }, 'is-size-7 disable-text']">Etapa 3</span>
							<span :class="[{ 'progress-label-active': getActiveStep == 4 }, 'is-size-7 disable-text']">Etapa 4</span>
						</div>

						<b-progress
							type="is-success"
							:value="getProgress"
							size="is-small"
						/>
					</div>
					<div
						class="column is-1-desktop is-1-tablet is-11-mobile"
						:class="{
							'is-flex is-justify-content-flex-end': isMobile,
							'is-flex is-justify-content-center': !isMobile
						}"
					>
						<span
							class="is-size-3 is-clickable"
							@click="handleCloseModal"
							v-text="'X'"
						/>
					</div>
				</div>
			</div>

			<div class="box-etapas">
				<input
					ref="uploader"
					class="is-hidden"
					type="file"
					multiple
					@input="enviarDocumento"
				>

				<Etapa1Upload
					v-if="getActiveStep == 1"
					:data="getEstruturaModalEnvioDocumentos.firstStep ? getEstruturaModalEnvioDocumentos.firstStep : { text: '', types: null }"
					@setType="type = $event"
					@moveStep="moveStep"
					@handleCloseModal="handleCloseModal"
				/>

				<Etapa2e3Upload
					v-if="getActiveStep == 2 || getActiveStep == 3"
					:data="getEstruturaModalEnvioDocumentos.secondStep || {}"
					:documento-upload="documentoUpload"
					@moveStep="moveStep"
					@handleCloseModal="handleCloseModal"
					@onEnviarDocumento="onEnviarDocumento"
					@cancelDocument="handleCancelDocument"
				/>

				<Etapa4Upload
					v-if="getActiveStep == 4"
					@moveStep="moveStep"
					@handleCloseModal="handleCloseModal"
				/>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Etapa1Upload from '@components/envio_documentos/ModalUpload/Etapa1Upload';
import Etapa2e3Upload from '@components/envio_documentos/ModalUpload/Etapa2e3Upload';
import Etapa4Upload from '@components/envio_documentos/ModalUpload/Etapa4Upload';

export default {
	name: 'ModalUpload',
	components: {
		Etapa1Upload,
		Etapa2e3Upload,
		Etapa4Upload
	},

	props: [
		'openDialogUpload',
		'documentoUpload',
		'cancelDocument'
	],

	data() {
		return {
			file: null,
			type: null,
			documento: null,
			maxFileSize: 15105151,
			acceptedImageTypes: ['image/jpg', 'image/jpeg', 'image/png', 'application/x-zip-compressed', 'application/pdf'],
			fileErrorLog: null,
			limitUploadFiles: 14,
			isLoading: false,
		};
	},

	computed: {
		...mapGetters('documentos', [
			'getProgress',
			'getActiveStep',
			'getEstruturaModalEnvioDocumentos',
		]),

		...mapGetters('ui', [
			'isMobile'
		]),

		modalSize() {
			return this.isMobile ? '90vw' : '70vw';
		}
	},

	async created() {
		this.isLoading = true
		this.setEstruturaModalEnvioDocumentos({});
		await this.loadEstruturaModalDocumentos(this.documentoUpload.docu_id).then()
		this.isLoading = false
	},

	methods: {
		...mapMutations('documentos', [
			'setProgress',
			'setActiveStep',
			'setEstruturaModalEnvioDocumentos'
		]),

		...mapActions('documentos', [
			'loadEstruturaModalDocumentos'
		]),

		handleCloseModal(redirect) {
			this.type = null;
			this.setProgress(25)
			this.setActiveStep(1)
			this.$emit('onCloseDialogUpload', redirect);
		},

		moveStep(object) {
			this.setActiveStep(object.step);
			this.setProgress(object.progress);
		},

		sendDocument(document) {
			this.$emit(
				'sendDocument',
				{
					document,
					file: this.file,
					type: this.type?.id,
				}
			);
		},

		enviarDocumento(event) {
			this.file = Array.from(event.target.files);
			this.fileErrorLog = [];
			this.fileMessageError = [];

			this.file.forEach(imageFile => {
				let extension = imageFile['name'].split(".").pop();

				if (!imageFile || !this.acceptedImageTypes.includes(imageFile['type'])) {
					this.$store.commit('ui/loading', false);
					this.fileMessageError.push(imageFile['name'] + ' com formato de arquivo invalido.');
					this.fileErrorLog.push(imageFile['name']);
				} else if (extension.toLowerCase() == 'jfif') {
					this.$store.commit('ui/loading', false);
					this.fileMessageError.push(imageFile['name'] + ' com formato de arquivo invalido.');
					this.fileErrorLog.push(imageFile['name']);
				} else {
					if (imageFile['size'] > this.maxFileSize) {
						this.$store.commit('ui/loading', false);
						this.fileMessageError.push(imageFile['name'] + ' excede o tamanho máximo permitido de 15 megas.');
						this.fileErrorLog.push(imageFile['name']);
					}
				}
			});

			if (this.file.length > this.limitUploadFiles) {
				this.$store.commit('ui/loading', false);
				this.fileMessageError.push('Excedido o limite máximo de 14 arquivos.');
				this.fileErrorLog.push('limite excedido');
			}

			if (this.fileErrorLog.length == 0) {
				this.sendDocument(this.documento);
			} else {
				this.$buefy.toast.open({
					message: '<p>Foram encontrados alguns erros:</p> <ul><li>' + this.fileMessageError.join("</li><li>") + '</li></ul>',
					type: 'is-danger'
				});
				event.target.value = null;
			}
		},
		onEnviarDocumento(document) {
			this.documento = document;
			this.$refs.uploader.click()
		},

		onClose() {
			this.$emit('onCloseDialogUpload');
		},

		handleCancelDocument() {
			this.$emit('cancelDocument', this.documento);
		},
	}
}
</script>

<style lang="scss" scoped>
.card {
	overflow: hidden;
}

.disable-text {
	color: #C2C2C2
}

.progress-label-active {
	color: #525252;
}

.box-etapas {
	padding: 10px 30px;
}
</style>
