<template>
	<transition name="fade">
		<div>
			<div
				v-if="data.length == 0"
				class="py-5 is-flex is-justify-content-center"
			>
				<slot name="empty" />
			</div>

			<div
				v-for="(documento, index) in data"
				:key="documento.docu_id"
				class="pt-3"
			>
				<hr
					v-if="index != 0"
					class="divider-horizontal"
				>

				<div class="columns is-desktop">
					<div class="column is-6-desktop is-12 is-flex is-flex-direction-column minimum-height">
						<div class="is-size-6 center-on-mobile pb-4">
							<span class="has-text-weight-bold document-name">{{ documento.docu_nome }}</span>

							<Info
								v-if="hasDescription(documento)"
								class="ml-2"
								:message="documento.docu_descricao"
							/>
						</div>

						<slot name="step" />

						<p
							v-if="showFeedback && hasFeedback(documento)"
							class="is-size-6 pt-4 center-on-mobile"
							:class="{ 'document-feedback-highlight': highlightFeedback }"
						>
							Parecer: {{ documento.docu_parecer }}
						</p>

						<div class="center-on-mobile last-update pt-6 is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-end">
							<div>
								<b-icon
									class="last-update-icon is-size-6"
									icon="clock-outline"
								/>

								<span
									class="is-size-6 has-text-weight-bold last-update-label"
								>
									Ultima alteração: {{
										documento.data_hora_alteracao == '-'
											? 'Nenhuma'
											: parseDate(documento.data_hora_alteracao)
									}}
								</span>
							</div>
						</div>
					</div>

					<div class="column vertical-divider is-1-desktop is-hidden-touch minimum-height" />

					<div class="column is-5-desktop is-12 is-flex is-flex-direction-column minimum-height">
						<div class="is-flex is-flex-direction-column is-align-items-center is-flex-grow-1 is-justify-content-center">
							<slot
								:documento="documento"
								name="buttons"
							/>

							<span
								v-if="isRequired(documento)"
								class="document-required"
							>
								* Obrigatório para matrícula
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import Info from '@components/Info';

export default {
	name: 'ListagemDocumentos',

	components: {
		Info,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},

		showFeedback: {
			type: Boolean,
			required: false,
			default: false,
		},

		highlightFeedback: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	methods: {
		parseDate(date) {
			if (!date || date == '-') {
				return '';
			}

			return date.split(' ')[0];
		},

		isRequired(document) {
			return document.docu_obrigatorio.toLowerCase() == 'sim';
		},

		hasFeedback(document) {
			return document.docu_parecer
				&& document.docu_parecer.toLowerCase() != 'sem parecer';
		},

		hasDescription(document) {
			return document.docu_descricao
				&& document.docu_descricao.trim().length > 0;
		},
	},
};
</script>

<style scoped>
.fade-enter-active {
	transition: opacity 1s;
}

.fade-leave-active {
	transition: opacity 0s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
	opacity: 0;
}

hr {
	background-color: #c2c2c2;
	height: 1px !important;
}

@media (max-width: 1020px) {
	::v-deep .center-on-mobile {
		text-align: center;
		align-items: center !important;
	}

	::v-deep .left-on-mobile {
		text-align: left;
		padding-left: 0px !important;
	}

	.minimum-height {
		min-height: inherit !important;
	}
}

.minimum-height {
	min-height: 14rem;
}

.document-name {
	color: #173048 !important;
}

.last-update * {
	vertical-align: middle;
}

.document-feedback-highlight,
.document-required {
	color: #FF1515;
}

.document-required {
	font-size: 0.90rem !important;
}
</style>
