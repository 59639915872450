<template>
	<div>
		<div id="docu_portal_inicio_descricao" v-html="content" />

		<div v-if="types.length > 0">
			<h3 class="type-title">Qual documento você enviará?</h3>

			<div
				v-for="type in types"
				:key="type.id"
				class="type-item"
			>
				<b-checkbox
					type="is-is-primary"
					:value="selectedType && selectedType.id == type.id"
					@input="selectedType = $event ? type : null"
				>
					<span class="type-label">{{ type.nome }}</span>
				</b-checkbox>
			</div>
		</div>

		<div class="is-justify-content-flex-end is-flex-direction-row columns py-5 mt-5 is-align-items-center">
			<div class="has-text-centered column is-1-widescreen is-2-desktop is-2-tablet is-12-mobile">
				<span
					class="is-clickable"
					@click="handleCloseModal"
				>
					<strong>Sair</strong>
				</span>
			</div>

			<div class="column is-6-desktop is-3-widescreen is-4-tablet is-12-mobile is-flex is-justify-content-center">
				<button
					class="button is-primary is-rounded"
					@click="next"
				>
					Continuar
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Etapa1Upload',

	props: {
		data: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			selectedType: null,
		};
	},

	computed: {
		content() {
			return this.data?.text || '';
		},

		types() {
			const types = this.data?.types;
			return types?.length ? types : [];
		},
	},

	methods: {
		next() {
			if (this.types.length > 0 && this.selectedType == null) {
				this.$store.dispatch(
					'ui/toast/openError',
					'Selecione um dos documentos listados para ser enviado'
				);

				return;
			} else if (this.types.length > 0) {
				this.$emit('setType', this.selectedType);
			}

			this.$emit('moveStep', {
				step: 2,
				progress: 50
			});
		},

		handleCloseModal() {
			this.$emit('handleCloseModal');
		},
	}
}
</script>

<style lang="scss" scoped>
::v-deep {
	.b-checkbox.checkbox input[type=checkbox]:checked + .check {
		background-color: var(--primary) !important;
		border-color: var(--primary) !important;
	}

	.b-checkbox.checkbox:hover input[type=checkbox]:hover + .check {
		border-color: var(--primary) !important;
	}

	.type-title {
		margin-bottom: 1rem;
		font-weight: 700;
		font-size: 1.5rem;
		color: #525252;
	}

	.type-item {
		display: inline-block;
		margin-right: 3rem;
		margin-bottom: 0.75rem;
	}

	.type-label {
		font-weight: 700;
		color: #525252;
	}

	@media (max-width: 768px) {
		.type-item {
			display: block;
			margin-right: 0;
			margin-bottom: 1rem;
		}
	}
	#docu_portal_inicio_descricao{
		word-wrap: break-word;
		ul{
			list-style: disc;
		}
		ol{
			list-style: decimal;
		}
	}
}
</style>
