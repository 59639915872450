<template>
	<div>
		<div>
			<h1 class="color-grey is-size-3 has-text-bold has-text-centered mb-4">
				Documento Enviado
			</h1>

			<p class="color-grey has-text-centered is-size-5">
				Seu documento será analisado.
			</p>
		</div>

		<div
			v-if="!isMobile"
			class="is-flex mt-5 is-justify-content-center"
		>
			<div class="is-flex is-align-items-center is-justify-content-flex-end">
				<p class="text-invalidados">
					Caso seu documento não esteja nos padrões solicitados, moveremos para a aba “Invalidados”.
				</p>
			</div>
			<div>
				<Icon
					data-tooltip="invalidado"
					class="pr-4 pt-2"
					file="timeline_documents"
					size="70:400"
				/>
			</div>
			<div class="is-flex is-flex-direction-column is-justify-content-space-between">
				<p class="text-em-analise">
					Enquanto estivermos analisando seu documento, você visualizará o seu status pela aba “Em análise”.
				</p>
				<p class="text-validados">
					Caso seu documento seja aceito, moveremos para a aba “Validados”.
				</p>
			</div>
		</div>

		<div
			v-if="isMobile"
			class="mt-5"
		>
			<div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center mt-5">
				<Icon
					data-tooltip="invalidado"
					class="pr-4"
					file="timeline_amp"
					size="30"
				/>
				<p>
					Enquanto estivermos analisando seu documento, você visualizará o seu status pela aba “Em análise”.
				</p>
			</div>

			<div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center mt-5">
				<Icon
					data-tooltip="invalidado"
					class="pr-4"
					file="timeline_error"
					size="30"
				/>
				<p>
					Caso seu documento não esteja nos padrões solicitados, moveremos para a aba “Invalidados”.
				</p>
			</div>

			<div class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center mt-5 mb-5">
				<Icon
					data-tooltip="invalidado"
					class="pr-4"
					file="timeline_success"
					size="30"
				/>
				<p>
					Caso seu documento seja aceito, moveremos para a aba “Validados”.
				</p>
			</div>
		</div>

		<div
			class="columns is-flex mt-5 mb-5"
			:class="{
				'is-justify-content-flex-end': !isMobile,
				'is-justify-content-center': isMobile,
			}"
		>
			<div
				class="column is-5-desktop is-5-tablet is-3-widescreen is-12-mobile"
				:class="{'has-text-centered': isMobile}"
			>
				<button
					class="button is-primary is-rounded"
					@click="handleCloseModal"
				>
					Concluir
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@components/Icon';

export default {
	name: 'Etapa4Upload',
	components: {
		Icon
	},
	computed: {
		...mapGetters('ui', [
			'isMobile'
		])
	},
	methods: {
		handleContinue() {
			this.$router.push('/envio-documentos');
		},

		handleCloseModal() {
			this.$emit('handleCloseModal', 'analise');
		}
	}
}
</script>

<style scoped>
.color-grey {
	color: #878787;
	font-weight: 500;
}

.text-invalidados {
	width: 80%;
}

.text-em-analise {
	padding-top: 35px;
}

.text-validados {
	height: 92px;
}
</style>
