<template>
	<ListagemDocumentos :data="data" show-feedback>
		<template #empty>
			<div class="is-flex is-flex-direction-column is-align-items-center empty-list">
				<div class="column is-5">
					<Icon class="primary-color" file="celebrate" size="100%" />
				</div>

				<h3 class="is-size-2 is-size-3-mobile has-text-centered justify-with-image">
					Você não tem documentos em<br>
					análise!
				</h3>

				<p class="column is-12 is-size-6 has-text-centered">
					<span v-if="showInvalidadoInfo">
						Na aba “Invalidados” você acompanha documentos que não foram aceitos pela nossa equipe, com o parecer.
					</span><br>
					Na aba “Não enviados” você verifica quais documentos ainda precisam ser entregues.<br>
					Na aba “Validados” você consegue monitorar o que já foi entregue.
				</p>
			</div>
		</template>

		<template #step>
			<div class="center-on-mobile step-info">
				<Icon class="step-info-icon pr-1" file="hourglass" size="1rem" />

				<span class="is-size-7 has-text-weight-bold">
					Em análise
				</span>
			</div>
		</template>

		<template #buttons="{ documento }">
			<b-button class="mb-4 button is-primary is-fullwidth is-rounded visualizar" @click="abrirDocumento(documento)">
				Visualizar arquivo enviado
			</b-button>

			<b-button class="mb-4 is-fullwidth button  is-primary is-rounded cancelar-envio" @click="onOpenDialog(documento)">
				Cancelar o envio deste documento
			</b-button>
		</template>
	</ListagemDocumentos>
</template>

<script>
import ListagemDocumentos from '@components/envio_documentos/ListagemDocumentos'
import Icon from '@components/Icon';
import { mapActions } from 'vuex';


export default {
	name: 'DocumentosPendentes',

	components: {
		ListagemDocumentos,
		Icon,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
		showInvalidadoInfo: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	methods: {
		...mapActions('documentos', [
			'abrirDocumento'
		]),

		cancelDocument(document) {
			this.$emit('cancelDocument', document);
		},

		onOpenDialog(document) {
			this.$emit('onOpenDialog', document);
		},

	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.justify-with-image {
		margin-top: -1.5rem;
		line-height: 1;
		color: #26496c;
		font-weight: bold;
	}

	.step-info {
		color: #FFB100;

		.step-info-icon {
			display: inline;

			svg {
				vertical-align: middle;
			}

			svg .hourglass-sand {
				fill: #f6cb5c !important;
			}

			svg .hourglass-base {
				fill: #9f7509 !important;
			}

			svg .hourglass-glass {
				fill: #d3d3d3 !important;
			}

			svg .hourglass-reflex {
				fill: #fff2c7 !important;
			}
		}
	}

	.cancelar-envio {
		background-color: #FFB100 !important;
		font-size: 0.84rem;

		&.is-focused:not(:active),
		&:focus:not(:active) {
			box-shadow: 0 0 0 0.125em #FFB100 !important;
		}

		&:hover,
		&:active,
		&:active:hover {
			background-color: #FFB100 !important;
		}
	}

	.visualizar {
		font-size: 0.84rem;
	}

}
</style>
