<template>
	<div>
		<div>
			<div class="is-flex is-justify-content-center is-align-items-center"
			:class="{
				'is-flex-direction-column-reverse': isMobile
			}">
				<Icon
					data-tooltip="invalidado"
					class="tabs-custom-icon pr-4 pt-2"
					file="warning"
					size="28"
				/>

				<h1 class="has-text-centered color-grey is-size-3 is-flex is-justify-content-center">
					Cancelar envio
				</h1>
			</div>

			<p class="color-grey has-text-centered is-size-5 mt-5">
				Você deseja desconsiderar o envio deste documento?
			</p>

			<p class="color-grey has-text-centered is-size-5 mt-5 mb-5">
				Uma vez cancelado, este documento será invalidado para análise.
			</p>
		</div>

		<div
			class="is-flex"
			:class="{
				'is-justify-content-flex-end': !isMobile,
				'is-justify-content-flex-center is-flex-direction-column': isMobile,
			}"
		>
			<div
				class="column is-4-tablet is-3-desktop is-2-fullhd is-12-mobile"
				:class="{
					'is-flex is-justify-content-flex-end': !isMobile,
					'has-text-centered': isMobile
				}"
			>
				<button
					class="button is-primary is-rounded is-outlined"
					@click="handleBack"
				>
					Voltar
				</button>
			</div>

			<div
				class="column is-4-desktop is-6-tablet is-3-fullhd is-12-mobile is-4-widrescreen"
				:class="{
					'is-flex is-justify-content-flex-end': !isMobile,
					'has-text-centered': isMobile
				}"
			>
				<button
					class="button is-primary is-rounded"
					:class="{'px-4': isMobile}"
					@click="handleCancel"
				>
					Sim, quero cancelar o envio
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '@components/Icon';
import { mapGetters } from 'vuex';

export default {
	name: 'Etapa3CancelDocument',
	components: {
		Icon
	},
	computed: {
		...mapGetters('ui', [
			'isMobile'
		])
	},
	methods: {
		handleCancel() {
			this.$emit('handleCancel');
		},

		handleBack() {
			this.$emit('handleBack');
		}
	}
}
</script>

<style scoped lang="scss">
::v-deep.tabs-custom-icon path {
	fill: #ff1515 !important;
}

.color-grey {
	color: #878787;
	font-weight: 500;
}
</style>
