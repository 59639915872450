<template>
	<div>
		<b-loading v-model="isLoading" :is-full-page="true" :can-cancel="false" />

		<div class="columns">
			<transition name="fade">
				<div v-if="!$store.getters['ui/isLoading']" class="column is-10-tablet is-offset-1-tablet is-10-fullhd">
					<Title>
						Envio de Documentos
					</Title>

					<div ref="start-page" tabindex="-1" />

					<div ref="panel" class="panel">
						<div ref="panelhead" class="panel-header columns columns-header is-desktop">
							<div class="progression-container column is-4" data-tooltip="progress" @click="toggleTooltip"
								@mouseenter="toggleTooltip" @mouseleave="toggleTooltip">
								<div class="progress-box">
									<div class="columns is-mobile progress-text">
										<div class="percent-number column is-narrow">
											{{ totalEntregues }}%
										</div>
										<div class="column progress-bar-desc">
											<div><strong>Documentos não enviados</strong> </div>
											<small>{{ totalNaoEntregues }} de {{ totalDocs }}</small>
										</div>
									</div>

									<div>
										<b-progress type="is-danger" size="is-small" :max="100" :value="totalEntregues" :show-value="false" />
									</div>
								</div>
							</div>
							<b-tabs v-model="activeTab" class="column tab-list">
								<b-tab-item ref="tab-0" :header-class="invalidadosTabClass" :visible="getInvalidados.length > 0">
									<template #header>
										<Icon data-tooltip="invalidado" class="tabs-custom-icon" file="error" size="20" @click="toggleTooltip"
											@mouseenter.native="toggleTooltip" @mouseleave.native="toggleTooltip" />
										<span data-tooltip="invalidado" v-on="isMobile ?
											{
												'click': toggleTooltip,
												'mouseleave': toggleTooltip,
											} :
											{
												'mouseleave': toggleTooltip,
												'mouseenter': toggleTooltip
											}">Invalidados</span>
									</template>
								</b-tab-item>
								<b-tab-item ref="tab-1" :header-class="naoEnviadosTabClass">
									<template #header>
										<Icon class="tabs-custom-icon" data-tooltip="naoenviado" file="warning" size="20"
											@click="toggleTooltip" @mouseenter.native="toggleTooltip" @mouseleave.native="toggleTooltip" />
										<span data-tooltip="naoenviado" v-on="isMobile ?
											{
												'click': toggleTooltip,
												'mouseleave': toggleTooltip,
											} :
											{
												'mouseleave': toggleTooltip,
												'mouseenter': toggleTooltip
											}">
											Não enviados
										</span>
									</template>
								</b-tab-item>

								<b-tab-item ref="tab-2" header-class="tab-em-analise">
									<template #header>
										<Icon class="tabs-custom-icon" file="hourglass" size="20" />
										<span>Em análise</span>
									</template>
								</b-tab-item>

								<b-tab-item ref="tab-3" header-class="tab-validados">
									<template #header>
										<Icon class="tabs-custom-icon" file="check" size="20" />
										<span>Validados</span>
									</template>
								</b-tab-item>
							</b-tabs>
						</div>

						<transition name="fade">
							<div v-if="activeTab == 0" class="panel-content">
								<DocumentosInvalidados :data="paginacaoInvalidados" @openDocument="openDocument"
									@sendDocument="sendDocument" @onOpenDialogUpload="onOpenDialogUpload" />
							</div>
						</transition>

						<transition name="fade ">
							<div v-if="activeTab == 1" class="panel-content">
								<DocumentosNaoEnviados :data="paginacaoNaoEnviados" :showInvalidadoInfo="getInvalidados.length > 0" @sendDocument="sendDocument"
									@onOpenDialogUpload="onOpenDialogUpload" />
							</div>
						</transition>

						<ModalUpload v-if="openDialogUpload" :open-dialog-upload="openDialogUpload"
							:documento-upload="documentoUpload" @sendDocument="sendDocument" @onCloseDialogUpload="onCloseDialogUpload"
							@cancelDocument="handleCancel" />

						<transition name="fade">
							<div v-if="activeTab == 2" class="panel-content">
								<DocumentosEmAnalise :data="paginacaoEmAnalise" :showInvalidadoInfo="getInvalidados.length > 0" @cancelDocument="handleCancel"
									@onOpenDialog="onOpenDialog" />

								<ModalConfirm :title-modal="titleModalConfirm" :open-dialog="openDialog" :on-open-dialog="onOpenDialog"
									:documento-modal="documentoModal" @cancelDocument="handleCancel" />
							</div>
						</transition>

						<transition name="fade">
							<div v-if="activeTab == 3" class="panel-content">
								<DocumentosValidados :data="paginacaoValidados" />
							</div>
						</transition>
						<div v-show="showTooltipInvalidados" id="tooltip-invalidados" ref="tooltipinvalidados"
							class="tooltip-message">
							Aqui ficam os documentos que você enviou, mas não atendem as regras de envio.
						</div>
						<div v-show="showTooltipNaoEnviados" id="tooltip-naoenviados" ref="tooltipnaoenviados"
							class="tooltip-message">
							Aqui ficam os documentos que você precisa enviar à instituição para prosseguir e concluir seus estudos.
						</div>
						<div v-show="showTooltipProgress" id="tooltip-progressbar" ref="tooltipprogressbar" class="tooltip-message">
							Aqui você acompanha quantos documentos seus ainda precisam ser enviados e validados.
						</div>
					</div>


					<transition name="fade">
						<Pagination v-if="activeTab == 0" v-model="currentPage" :per-page="5" :total="getInvalidados.length"
							hide-on-single-page />
					</transition>

					<transition name="fade">
						<Pagination v-if="activeTab == 1" v-model="currentPage" :per-page="5" :total="getNaoEnviados.length"
							hide-on-single-page />
					</transition>

					<transition name="fade">
						<Pagination v-if="activeTab == 2" v-model="currentPage" :per-page="5" :total="getEmAnalise.length"
							hide-on-single-page />
					</transition>

					<transition name="fade">
						<Pagination v-if="activeTab == 3" v-model="currentPage" :per-page="5" :total="getValidados.length"
							hide-on-single-page />
					</transition>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import Icon from '@components/Icon';
import DocumentosInvalidados from '@components/envio_documentos/DocumentosInvalidados';
import DocumentosNaoEnviados from '@components/envio_documentos/DocumentosNaoEnviados';
import DocumentosEmAnalise from '@components/envio_documentos/DocumentosEmAnalise';
import DocumentosValidados from '@components/envio_documentos/DocumentosValidados';
import Pagination from '@components/Pagination';
import ModalConfirm from '@components/envio_documentos/ModalConfirm';
import Title from '@components/Title';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ModalUpload from '@components/envio_documentos/ModalUpload';
import http from "@commons/http";

export default {
	name: 'EnvioDocumentos',

	components: {
		Icon,
		DocumentosInvalidados,
		DocumentosNaoEnviados,
		DocumentosEmAnalise,
		DocumentosValidados,
		Pagination,
		ModalConfirm,
		Title,
		ModalUpload
	},

	data() {
		return {
			activeTab: 1,
			currentPage: 0,
			openDialog: false,
			titleModalConfirm: '',
			documentoModal: false,
			openDialogUpload: false,
			documentoUpload: null,
			showTooltipInvalidados: false,
			showTooltipNaoEnviados: false,
			showTooltipProgress: false,
			tooltipMessage: '',
		};
	},

	computed: {
		...mapGetters('documentos', [
			'getInvalidados',
			'getNaoEnviados',
			'getEmAnalise',
			'getValidados',
			'isLoading',
			'contemDados',
		]),
		...mapGetters('ui', ['isMobile']),

		paginacaoInvalidados: function () {
			return this.getInvalidados.slice(this.currentPage * 5, this.currentPage * 5 + 5);
		},

		paginacaoNaoEnviados: function () {
			return this.getNaoEnviados.slice(this.currentPage * 5, this.currentPage * 5 + 5);
		},

		paginacaoEmAnalise: function () {
			return this.getEmAnalise.slice(this.currentPage * 5, this.currentPage * 5 + 5);
		},

		paginacaoValidados: function () {
			return this.getValidados.slice(this.currentPage * 5, this.currentPage * 5 + 5);
		},

		naoEnviadosTabClass: function () {

			let myClass = 'tab-nao-enviados';

			if (this.getNaoEnviados != undefined && this.getNaoEnviados.length > 0) {
				return myClass + ' pendente-red-alert';
			}
			return myClass;
		},

		invalidadosTabClass: function () {
			let myClass = 'tab-invalidados';

			if (this.getInvalidados != undefined && this.getInvalidados.length > 0) {
				return myClass + ' pendente-red-alert';
			}
			return myClass;
		},

		totalDocs: function () {
			let totalDocs = 0;

			if (this.getInvalidados != undefined) {
				totalDocs += this.getInvalidados.length;
			}
			if (this.getNaoEnviados != undefined) {
				totalDocs += this.getNaoEnviados.length;
			}
			if (this.getEmAnalise != undefined) {
				totalDocs += this.getEmAnalise.length;
			}
			if (this.getValidados != undefined) {
				totalDocs += this.getValidados.length;
			}

			return totalDocs;
		},

		totalNaoEntregues: function () {
			let total = 0;
			if (this.getInvalidados != undefined) {
				total += this.getInvalidados.length;
			}

			if (this.getNaoEnviados != undefined) {
				total += this.getNaoEnviados.length;
			}

			return total;
		},

		totalEntregues: function () {

			if (this.totalDocs == 0) {
				return this.totalDocs;
			}

			const rawValue = this.totalNaoEntregues / this.totalDocs * 100;
			return Math.ceil(rawValue);
		}
	},



	watch: {
		activeTab: function () {
			this.currentPage = 0;
		},

		currentPage: function (actual, previous) {
			if (actual != previous) {
				this.$refs['start-page'].focus();
			}
		}
	},

	async created() {
		await this.todosDocumentos();
		const possuiInvalidados = this.getInvalidados.length > 0;
		const possuiNaoEnviados = this.getNaoEnviados.length > 0;
		const possuiEmAnalise = this.getEmAnalise.length > 0;
		if (possuiInvalidados) {
			this.activeTab = 0;
		} else if (possuiNaoEnviados) {
			this.activeTab = 1;
		} else if (possuiEmAnalise) {
			this.activeTab = 2;
		} else {
			this.activeTab = 3;
		}
	},


	methods: {
		...mapActions('documentos', [
			'todosDocumentos',
			'cancelarDocumento',
			'enviarDocumento',
			'enviarMergeDocumento',
			'docUploadFinished',
		]),

		...mapMutations('documentos', [
			'setActiveStep',
			'setProgress'
		]),

		toggleTooltip(event) {

			if (event.type == 'click' && !this.isMobile) {
				return;
			}

			const panel = this.$refs.panel.getBoundingClientRect();
			const panelhead = this.$refs.panelhead;
			const rect = event.target.getBoundingClientRect();

			let top = panelhead.clientHeight;
			let left = rect.left + window.scrollX - panel.left;

			if (this.isMobile) {
				left = panelhead.getBoundingClientRect().left;
			}

			switch (event.target.dataset.tooltip) {
				case 'progress':
					if (this.showTooltipProgress) {
						this.showTooltipProgress = false;
						return;
					} else {
						if (this.isMobile) {
							top -= panelhead.clientHeight / 3
						}


						this.$refs.tooltipprogressbar.style.left = `${left}px`;
						this.$refs.tooltipprogressbar.style.top = `${top}px`;
						this.showTooltipProgress = true;
						this.showTooltipNaoEnviados = false;
						this.showTooltipInvalidados = false;
					}
					break;
				case 'invalidado':
					if (this.showTooltipInvalidados) {
						this.showTooltipInvalidados = false;
						return;
					} else {
						this.$refs.tooltipinvalidados.style.left = `${left}px`;
						this.$refs.tooltipinvalidados.style.top = `${top}px`;
						this.showTooltipInvalidados = true;


						this.showTooltipProgress = false;
						this.showTooltipNaoEnviados = false;
					}
					break;
				case 'naoenviado':
					if (this.showTooltipNaoEnviados) {
						this.showTooltipNaoEnviados = false;
						return;
					} else {
						this.$refs.tooltipnaoenviados.style.left = `${left}px`;
						this.$refs.tooltipnaoenviados.style.top = `${top}px`;
						this.showTooltipNaoEnviados = true;
						this.showTooltipProgress = false;
						this.showTooltipInvalidados = false;
					}
					break;
			}

		},

		async handleCancel(document) {
			const response = await this.cancelarDocumento(document);

			this.$buefy.toast.open({
				message: response.success ? 'Envio cancelado com sucesso' : (response.message != null ? response.message : 'Erro ao cancelar o arquivo, tente novamente'),
				type: response.success ? 'is-success' : 'is-danger',
				duration: 4000
			});


			if (!response.success) {
				setTimeout(() => {
					this.todosDocumentos();
				}, 1000);
			}

			if (response.success) {
				this.openDialogUpload = false;
				this.setActiveStep(1);
				this.setProgress(25);
			}
		},

		processDocPending(docs) {
			for (const doc of docs) {
				if (doc.docu_rejeitado == 't') {
					this.invalidados.push(doc);
					continue;
				}

				if (doc.docu_ordem == 1) {
					this.naoEnviados.push(doc);
					continue;
				}

				this.emAnalise.push(doc);
			}
		},

		processDocDone(docus) {
			this.validados = docus;
		},

		async sendDocument({ document, file, type = null }) {
			this.arquivosEnviados = [];
			this.arquivosNaoEnviados = [];
			this.qtdEnviados = 0;
			this.qtdAlerta = '';
			this.qtdAlertaNaoEnviados = '';

			Object.assign(
				document,
				{
					fileImage: file[0],
					type,
				}
			);

			const response = await this.enviarDocumento(document);

			if (response) {
				this.arquivosEnviados.push(file[0]['name']);
				this.qtdEnviados++;
			} else {
				this.arquivosNaoEnviados.push(file[0]['name']);
			}

			if (file.length > 1) {
				file.forEach(async (fileImage, index) => {
					if (index != 0) {
						Object.assign(document, { fileImage: fileImage });
						const response = await this.enviarMergeDocumento(document);

						if (response) {
							this.arquivosEnviados.push(fileImage['name']);
							this.qtdEnviados++;
						} else {
							this.arquivosNaoEnviados.push(fileImage['name']);
						}

						if (this.qtdEnviados == file.length) {

							if (this.arquivosNaoEnviados.length > 0) {
								await this.cancelarDocumento(document);

								if (this.qtdAlertaNaoEnviados == '') {
									this.$buefy.toast.open({
										message: (this.arquivosNaoEnviados.length == this.qtdEnviados) ? 'Erro ao enviar os arquivos' : 'Erro ao enviar um ou mais arquivos',
										type: 'is-danger'
									});

									this.qtdAlertaNaoEnviados = 1;
								}

							}

							if (this.qtdAlerta == '') {
								this.$buefy.toast.open({
									message: (this.arquivosEnviados.length > 1) ? 'Arquivos enviados com sucesso' : 'Arquivo enviado com sucesso',
									type: 'is-success'
								});

								if (this.arquivosNaoEnviados.length == 0) {
									await this.docUploadFinished(document);
								}

								this.qtdAlerta = 1;
								this.uploadSucess()
							}

							await this.todosDocumentos()
								.catch(() => { })
								.finally(() => {
									this.$store.commit('ui/loading', false);
								});
						}
					}
				});
			} else {

				if (this.arquivosNaoEnviados.length > 0) {
					await this.cancelarDocumento(document);
					this.$buefy.toast.open({
						message: 'Erro ao enviar o arquivo',
						type: 'is-danger'
					});
				} else {
					this.$buefy.toast.open({
						message: 'Arquivo enviado com sucesso',
						type: 'is-success'
					});

					if (this.arquivosNaoEnviados.length == 0) {
						await this.docUploadFinished(document);
					}

					this.uploadSucess();
				}

				await this.todosDocumentos()
					.catch(() => { })
					.finally(() => {
						this.$store.commit('ui/loading', false);
					});
			}
		},

		openDocument(document) {
			window.open(document.docu_link, '_blank');
		},

		cancelDocument(document) {
			this.loading = true;

			http.post(
				'/v1/aluno/cancelar-documentos?docu_id=' + document.docu_id,
				{}
			)
				.then(async (response) => {
					this.loading = false;

					if (response.status != 200) {
						return false;
					}

					this.$store.commit('ui/loading', true);

					this.$buefy.toast.open({
						message: 'Envio cancelado com sucesso',
						type: 'is-success'
					});

					await this.todosDocumentos()
						.catch(() => { })
						.finally(() => {
							this.$store.commit('ui/loading', false);
						});
				})
				.catch(() => {
					this.loading = false;

					this.$buefy.toast.open({
						message: 'Erro ao cancelar o arquivo, tente novamente',
						type: 'is-danger'
					});
				});
		},

		onOpenDialog(document) {
			this.openDialog = !this.openDialog;
			this.documentoModal = document;
			this.titleModalConfirm = 'Cancelar envio';
		},

		onOpenDialogUpload(document) {
			this.openDialogUpload = !this.openDialogUpload;
			this.documentoUpload = document;
		},
		onCloseDialogUpload(redirect) {
			this.openDialogUpload = !this.openDialogUpload;
			if (redirect != undefined && redirect != null && redirect != "" && redirect == 'analise') {
				this.activeTab = 2;
			}
		},

		uploadSucess() {
			this.setActiveStep(3);
			this.setProgress(75);
		}
	},

};
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

.progress-bar-desc {
	padding-left: 0 !important;
}

.progress-text {
	margin-bottom: 0;
}

@media(max-width: 768px) {
	.tab-list {
		display: block !important;
	}
}

.tab-list {
	display: flex;
	align-items: flex-end;
	padding-left: 0;
	padding-right: 0;
}

.percent-number {
	font-size: xx-large;
	color: #ff1515;
	font-weight: 700;
}

.columns.columns-header {
	margin: 0px;
	flex-direction: row-reverse;
}

h2.title {
	border-bottom: 1px solid #c2c2c2;
	padding-bottom: 16px;
	margin-bottom: 32px;
}

.panel {
	position: relative;
	background: $white;
	background: var(--white);
	border-radius: 8px;
	box-shadow: none;

	&-header {
		border-radius: 8px 8px 0 0;
		background: #f2f2f2;
		padding: 10px 20px 0 20px;
	}

	&-content {
		padding: 30px 48px;
	}
}

.fade-enter-active {
	transition: opacity 1s;
}

.fade-leave-active {
	transition: opacity 0s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
	{
	opacity: 0;
}

.student {
	color: #173048 !important;
	font-weight: 700 !important;
}

.tabs-custom-icon {
	position: relative;
	top: 3px;
	margin-right: 8px;
}

::v-deep {
	.tabs .is-active {
		a {
			margin-bottom: -2;
			padding-bottom: 0;
			border-bottom-style: none;
			flex-wrap: wrap;
		}

		a::after {
			content: '';
			position: relative;
			width: 100%;
			margin-top: 0.5em;
			margin-top: calc(0.5em - 1px);
			margin-left: -2px;
			margin-right: -2px;
			border: 2px transparent solid;
			border-radius: 2px;
			display: block;
		}
	}
}

::v-deep .pendente-red-alert {
	border: solid 2px;
	border-color: #ff1515;
	border-radius: 20px;
}

::v-deep .tab-invalidados.is-active,
::v-deep .tab-nao-enviados.is-active {
	a::after {
		border-color: #ff1515 !important;
	}

	.tabs-custom-icon path {
		fill: #ff1515 !important;
	}
}

::v-deep .tab-em-analise.is-active {
	a::after {
		border-color: #ffb100 !important;
	}

	.tabs-custom-icon .hourglass-sand {
		fill: #f6cb5c !important;
	}

	.tabs-custom-icon .hourglass-base {
		fill: #9f7509 !important;
	}

	.tabs-custom-icon .hourglass-glass {
		fill: #d3d3d3 !important;
	}

	.tabs-custom-icon .hourglass-reflex {
		fill: #fff2c7 !important;
	}
}

::v-deep .tab-validados.is-active {
	a::after {
		border-color: #00ae8e !important;
	}

	.tabs-custom-icon path {
		fill: #00796a !important;
	}
}

.tooltip-message {
	padding: 1rem;
	position: absolute;
	background: #cdcfd2;
	color: #3b3b3b;
	opacity: 0.95;
	z-index: 9999 !important;
	max-width: 40%;
	text-align: justify;
	text-justify: inter-word;
	border-radius: 8px 8px 8px 8px;


}

@media(max-width: 768px) {
	.tooltip-message {
		padding: 1rem;
		position: absolute;
		background: #cdcfd2;
		color: #3b3b3b;
		opacity: 0.95;
		z-index: 9999 !important;
		min-width: 80%;
		text-align: justify;
		text-justify: inter-word;
		border-radius: 8px 8px 8px 8px;

	}

}
</style>
