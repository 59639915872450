<template>
	<div v-if="openDialog">
		<div
			class="backdrop"
			@click="onOpenDialog"
		/>

		<div
			class="dialog"
			@click="onOpenDialog"
		>
			<div class="modal-header text-center">
				<div class="center-on-mobile modal-info">
					<Icon
						class="modal-info-icon"
						file="warning"
						size="1.6rem"
					/>

					<span class="has-text-weight-bold modal-title is-primary">
						{{ titleModal }}
					</span>
				</div>
			</div>

			<div class="modal-body text-center">
				<p>Você deseja desconsiderar este envio de documento?</p>
				<p>Uma vez cancelado, este documento será invalidado para análise.</p>
			</div>

			<div class="btn">
				<button
					class="mb-4 button is-primary is-fullwidth is-rounded py-2"
					@click="cancelDocument(documentoModal)"
				>
					Sim, quero cancelar o envio
				</button>
				<button class="mb-4 button primary-border is-fullwidth is-rounded py-2">
					Voltar
				</button>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
  .modal-title {
    font-weight:  700;
    font-size: 1.6rem !important;
    color: var(--primary);
  }
  .modal-body {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  p {
    font-weight:  700;
    color: #525252;
    margin-bottom: 1rem;
  }
  .btn{
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 18px;
  }
  .backdrop {
    display: flex ;
    background: rgba(0, 0, 0, 0.25);
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 50;
  }
  .dialog {
    text-align: center;
    border-radius: 10px;
    position: fixed;
    top: 35%;
    background: #fff;
    width: 318px;
    height: 20.6rem;
    right: 0;
    left: 0;
    margin: 0 auto;
    padding: 20px;
    z-index: 51;
  }
  .dialog h1 {
    padding-bottom: 30px;
    font-size: 20px;
  }
  .dialog p {
    font-size: 16px;
  }
  .primary-border {
    border-color: var(--primary);
    color: var(--primary);
  }
  .modal-info-icon {
    display: inline;
  }
  ::v-deep {
    .modal-info {
      .modal-info-icon {
        svg {
          vertical-align: middle;
          position: relative;
          bottom: 0.3rem;
        }
        svg * {
          fill: #FF1515;
        }
      }
    }
  }
</style>

<script>
import Icon from '@components/Icon';
export default {
	name: "ModalConfirm",
	components: {
		Icon
	},
	props:[
		'openDialog',
		'onOpenDialog',
		'titleModal',
		'documentoModal'
	],
	methods: {
		cancelDocument(document) {
			this.$emit('cancelDocument', document);
		},
	},
}
</script>
