<template>
	<ListagemDocumentos :data="data" show-feedback highlight-feedback>
		<template #empty>
			<div class="is-flex is-flex-direction-column is-align-items-center empty-list">
				<div class="column is-5">
					<Icon class="primary-color" file="celebrate" size="100%" />
				</div>

				<h3 class="is-size-2 is-size-3-mobile has-text-centered justify-with-image">
					Nenhum documento invalidado.
				</h3>

				<p class="column is-12 is-size-6 has-text-centered">
					Você não possui documentos invalidados no momento.<br>
					Clique na aba “Não Enviados” para verificar se há algo à entregar, ou na aba “Em análise” para<br>
					verificar se suas entregas ainda estão sob validação da nossa equipe.
				</p>
			</div>
		</template>

		<template #step>
			<div class="center-on-mobile step-info">
				<Icon class="step-info-icon pr-1" file="error" size="1rem" />

				<span class="is-size-7 has-text-weight-bold">
					Documento invalidado
				</span>
			</div>
		</template>

		<template #buttons="{ documento }">
			<!-- <b-button
				class="mb-4 is-primary is-fullwidth is-rounded"
				:disabled="!hasLink(documento)"
				@click="abrirDocumento(documento)"
			>
				Visualizar arquivo enviado
			</b-button> -->
			<b-button class="mb-4 button is-fullwidth is-primary is-rounded cancelar-envio"
				@click="onOpenDialogUpload(documento)">
				Enviar um novo documento
			</b-button>
		</template>
	</ListagemDocumentos>
</template>

<script>
import ListagemDocumentos from '@components/envio_documentos/ListagemDocumentos'
import Icon from '@components/Icon';
import { mapActions } from 'vuex';

export default {
	name: 'DocumentosInvalidados',

	components: {
		ListagemDocumentos,
		Icon,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
	},

	data() {
		return {
			file: null,
		};
	},

	methods: {
		...mapActions('documentos', [
			'abrirDocumento',
			'enviarDocumento'
		]),

		async handleSubmit(documento, file) {
			const response = await this.enviarDocumento({
				document: documento,
				file: file
			});

			this.$buefy.toast.open({
				message: response ? 'Arquivo enviado com sucesso' : 'Erro ao enviar o arquivo, tente novamente',
				type: response ? 'is-success' : 'is-danger'
			});
		},

		hasLink(document) {
			return String(document.docu_link).length > 0;
		},

		openDocument(document) {
			this.$emit('openDocument', document);
		},

		sendDocument(document) {
			this.$emit('sendDocument', { document, file: this.file });
		},

		onOpenDialogUpload(document) {
			this.$emit('onOpenDialogUpload', document);
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.justify-with-image {
		margin-top: -1.5rem;
		line-height: 1;
		color: #26496c;
		font-weight: bold;
	}

	.step-info {
		color: #FF1515;

		.step-info-icon {
			display: inline;

			svg {
				vertical-align: middle;
			}

			svg * {
				fill: #FF1515;
			}
		}
	}
}
</style>
