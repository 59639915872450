<template>
	<div
		class="info-wrapper"
		@mouseover="showInfo"
		@mouseleave="closeInfo"
		@touchstart="showInfo"
		@click="showInfo"
	>
		<b-icon
			icon="information-outline"
			custom-size="mdi-18px"
		/>

		<transition
			name="fade"
			:duration="500"
		>
			<article
				v-show="open"
				class="notification"
			>
				<button
					type="button"
					class="delete"
					@click.capture.stop="closeInfo"
				/>

				<div class="media">
					<div class="media-content">
						<!-- eslint-disable vue/no-v-html -->
						<div v-html="message" />
					</div>
				</div>
			</article>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'Info',

	props: {
		message: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			open: false,
		};
	},

	methods: {
		showInfo() {
			this.open = true;
		},

		closeInfo() {
			this.open = false;
		},
	}
}
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

.info-wrapper {
	display: inline-block;

	color: $text !important;
	color: var(--text) !important;

	.notification {
		background: #cdcfd2;
		color: #3b3b3b;
		opacity: 0.95;
		z-index: 9999 !important;
		max-width: 50%;
		text-align: justify;
		text-justify: inter-word;
		border-radius: 0 0% 8px 8px;
		transform: translateY(-1.5rem);
	}

	.notification.fade-enter-active,
	.notification.fade-leave-active {
		transition: opacity .5s;
	}

	.notification.fade-enter,
	.notification.fade-leave-to {
		opacity: 0 !important;
	}

	.notification.fade-enter-to,
	.notification.fade-leave {
		opacity: 0.95 !important;
	}

	.icon {
		width: auto;
		height: auto;
		font-size: 1rem;
		line-height: 1.5rem;
		vertical-align: middle;
		cursor: pointer;
	}
}

@media only screen and (max-width: 1020px) {
	.info-wrapper .notification {
		width: 100vw;
		left: -1.5rem;
		max-width: 100vw;
		transform: none;
	}
}
</style>
