<template>
	<ListagemDocumentos
		:data="data"
		show-feedback
	>
		<template #empty>
			<div class="is-flex is-flex-direction-column is-align-items-center empty-list">
				<div class="column is-5">
					<Icon
						class="primary-color"
						file="puzzle"
						size="100%"
					/>
				</div>

				<h3 class="is-size-2 is-size-3-mobile has-text-centered justify-with-image">
					Nenhum documento validado.
				</h3>

				<p class="column is-12 is-size-6 has-text-centered">
					Clique na aba “Não enviados” e veja a relação de documentos à entregar.<br>
					Atente-se à prioridade de entrega dos documentos .
				</p>
			</div>
		</template>

		<template #step>
			<div class="center-on-mobile step-info">
				<Icon
					class="step-info-icon pr-1"
					file="check"
					size="1rem"
				/>

				<span class="is-size-7 has-text-weight-bold">
					Validado
				</span>
			</div>
		</template>

		<template #buttons="{ documento }">
			<b-button
				class="mb-4 button is-primary is-fullwidth is-rounded"
				@click="abrirDocumento(documento)"
			>
				Visualizar arquivo enviado
			</b-button>
		</template>
	</ListagemDocumentos>
</template>

<script>
import ListagemDocumentos from '@components/envio_documentos/ListagemDocumentos'
import Icon from '@components/Icon';
import { mapActions } from 'vuex';

export default {
	name: 'DocumentosValidados',

	components: {
		ListagemDocumentos,
		Icon,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
	},

	methods: {
		...mapActions('documentos', [
			'abrirDocumento'
		]),
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.justify-with-image {
		margin-top: -1.5rem;
		line-height: 1;
		color: #26496c;
		font-weight: bold;
	}

	.step-info {
		color: #37C36F;

		.step-info-icon {
			display: inline;

			svg {
				vertical-align: middle;
			}

			svg * {
				fill: #37C36F;
			}
		}
	}
}
</style>
