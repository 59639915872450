<template>
	<div>
		<div v-if="!showCancelInstructions">
			<div v-html="data.head" />

			<div class="is-justify-content-space-between mt-6 is-flex-direction-row" :class="{
				'is-flex': !isMobile
			}">
				<div v-if="showImage" class="column is-12-mobile is-flex box-media is-flex-direction-column" :class="{
					'is-6-desktop': showVideo,
					'is-8-desktop is-offset-2-desktop is-justify-content-center': !showVideo
				}">
					<img :src="data.imageURL" alt="Imagem do documento" class="doc-image">

					<div class="pt-4" v-html="data.imageDescription" />
				</div>

				<div v-if="showVideo" class="column is-6-desktop is-12-mobile" :class="{
					'is-6-desktop': showImage,
					'is-8-desktop is-offset-2-desktop is-justify-content-center': !showImage
				}">
					<div v-html="data.videoHTML" width="100%" :height="imageHeight" class="doc-video" />
					<div class="pt-4" v-html="data.videoDescription" />
				</div>
			</div>

			<div v-if="getActiveStep == 2"
				class="is-justify-content-flex-end is-flex-direction-row columns py-5 mt-5 is-align-items-center">
				<div class="has-text-centered column is-1-widescreen is-2-desktop is-2-tablet is-12-mobile">
					<span class="is-clickable" @click="backStep">
						<strong>Voltar</strong>
					</span>
				</div>

				<div class="column is-6-desktop is-3-widescreen is-4-tablet is-12-mobile is-flex is-justify-content-center">
					<button class="button is-primary is-rounded" @click="onEnviarDocumento">
						Enviar Documento
					</button>
				</div>
			</div>

			<div v-if="getActiveStep == 3" class="py-5 mt-5 is-align-items-center">
				<div class="columns is-justify-content-flex-end is-flex-direction-row">
					<div class="column is-6-desktop is-3-widescreen is-6-tablet is-12-mobile is-flex is-justify-content-center">
						<button class="button is-primary is-rounded" @click="handleOpenDocument">
							Visualizar documento
						</button>
					</div>
				</div>

				<div class="columns is-justify-content-flex-end is-flex-direction-row">
					<div class="column is-6-desktop is-3-widescreen is-6-tablet is-12-mobile is-flex is-justify-content-center">
						<button class="is-rounded button btn-cancelar-envio" @click="handleShowCancelConfirmation(true)">
							Cancelar o envio realizado
						</button>
					</div>

					<div class="column is-6-desktop is-3-widescreen is-6-tablet is-12-mobile is-flex is-justify-content-center">
						<button class="button is-primary is-rounded is-outlined" @click="next">
							Prosseguir
						</button>
					</div>
				</div>
			</div>
		</div>

		<Etapa3CancelDocument v-if="showCancelInstructions" @handleCancel="handleCancelDocument"
			@handleBack="handleShowCancelConfirmation(false)" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Etapa3CancelDocument from '@components/envio_documentos/ModalUpload/Etapa3CancelDocument';

export default {
	name: 'Etapa2e3Upload',
	components: {
		Etapa3CancelDocument,
	},
	props: {
		data: {
			type: Object,
			required: true,
			default: () => { }
		},
		documentoUpload: {
			required: true,
		},
	},
	data() {
		return {
			cancelInstructions: false,
		}
	},
	computed: {
		...mapGetters('ui', [
			'isMobile'
		]),

		...mapGetters('documentos', [
			'getActiveStep',
			'getEmAnalise'
		]),

		imageHeight() {
			return '90px';
		},

		showVideo() {
			return (this.data.videoHTML != null && this.data.videoHTML != undefined) && this.data.videoHTML?.length > 0
		},

		showImage() {
			return (this.data.imageURL != null && this.data.imageURL != undefined) && this.data.imageURL?.length > 0
		},

		showCancelInstructions() {
			return this.cancelInstructions;
		}
	},
	methods: {
		...mapActions('documentos', [
			'abrirDocumento'
		]),

		next() {
			if (this.getActiveStep == 3) {
				this.$emit('moveStep', {
					step: 4,
					progress: 100
				});
			}
		},

		backStep() {
			this.$emit('moveStep', {
				step: 1,
				progress: 25
			});
		},

		handleCloseModal() {
			this.$emit('handleCloseModal');
		},

		onEnviarDocumento() {
			this.$emit('onEnviarDocumento', this.documentoUpload);
		},

		handleCancelDocument() {
			this.$emit('cancelDocument')
		},

		handleShowCancelConfirmation(cancelInstructions) {
			this.cancelInstructions = cancelInstructions;
		},

		handleOpenDocument() {
			const docArray = this.getEmAnalise.filter((document) => {
				return this.documentoUpload.docu_id === document.docu_id
			});

			this.abrirDocumento(docArray[0])
		}
	}
}
</script>

<style scoped lang="scss">
.doc-image {
	width: 100%;
	height: 400px;
	object-fit: contain;
}

.doc-video {
	height: 400px;
}

.btn-cancelar-envio {
	background-color: #FFB100;
	color: #FFF;
	border: 0;
}

.btn-cancelar-envio:hover {
	border: 0;
}
</style>
